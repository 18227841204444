import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DemoComponent} from './demo/demo.component';
import {ContactComponent} from './contact/contact.component';
import {ScheduleComponent} from './schedule/schedule.component';
import {TestingComponent} from './testing/testing.component';
import {FullScreenChatComponent} from './full-screen-chat/full-screen-chat.component';
import {MultipleVideoCallComponent} from './multiple-video-call/multiple-video-call.component';


const routes: Routes = [
  {
    path: 'testing',
    component: TestingComponent,
  }, {
    path: 'demo',
    component: DemoComponent,
  }, {
    path: 'contact',
    component: ContactComponent,
  }, {
    path: 'schedule',
    component: ScheduleComponent,
  }, {
    path: 'chat',
    component: FullScreenChatComponent,
  }, {
    path: 'video',
    component: MultipleVideoCallComponent,
  },
  { path: '',   redirectTo: '/video', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
