import {Component, OnInit, Sanitizer} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {NgWhiteboardService} from 'ng-whiteboard';

@Component({
    selector: 'app-demo',
    templateUrl: './demo.component.html',
    styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

    name;
    url;
    isShowChat = true;
    color = '#333333';
    backgroundColor = '#ffffff';
    size = '5px';
    isActive = false;

    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private whiteboardService: NgWhiteboardService
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.name = params.userid;
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://realpulsewebdemo.onrender.com/chat.html?userid=' +
                this.name + '&nickname=' + this.name);
        });
    }

    onClickSign() {
      this.isShowChat = !this.isShowChat;
    }

    erase() {
        this.whiteboardService.erase();
    }

    setSize(size) {
        this.size = size;
        this.isActive = false;
    }

    save() {
        this.whiteboardService.save();
        this.onClickSign();
    }

    undo() {
        this.whiteboardService.undo();
    }

    redo() {
        this.whiteboardService.redo();
    }

    addImage(fileInput) {
        const file = fileInput.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            this.whiteboardService.addImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }
}
